.maparea-c{
    padding: 50px 0;
    display: flex;
    justify-content: center;

    .title{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 30px 0;
        width: 100%;
        font-size: 20px;
        font-weight: 800;
    }
    .map{
        margin: 20px 0;

        span{
            width:100%;
            height:100%;
        }

        iframe{
            width: 100%;
            height:100%;
        }
    }

    .text{
        padding: 20px 25px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        font-size: 16px;
        overflow: hidden;

        h1{
            font-size: 16px;
            font-weight: inherit;
        }

        h2{
            padding: 10px 0px;
            color: grey;
            font-size: 16px;
            font-weight: inherit;
        }

        h3{
            font-size: 16px;
            font-weight: inherit;
        }
    }
}

.gridarea-c{
    min-height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px 50px;

    .text-container{
        position: absolute;
        z-index: 100;
        display: flex;
        left: 0;
        width: 90%;
    }

    .text-box{
        display: flex;
        justify-content: center;
        align-items: left;
        flex-direction: column;
        padding: 30px;

        a{
            text-decoration: none;
            color:inherit;

            border: 1px solid black;
            padding: 5px 10px;
            border-radius: 8px;

            &:hover{
              background-color:rgba(255, 255, 255, 0.3);
            }
        }

        h1{
            font-size: 70px;
            font-weight: inherit;
        }

        h2{
            font-size: 50px;
            font-weight: inherit;
        }

        h3{
            font-size: 30px;
            padding-bottom: 20px;
            font-weight: inherit;
        }
    }
    .image-container{
        position: relative;
        width: 67%;
        height: 400px;
        display: flex;

        img{
            width: 75%;
            height: 100%;
            position: absolute;
            object-fit: cover;
        }
    }
}

.card-area-c{
    display: flex;
    padding: 50px calc(15px + 8.333%);
    font-size: 14px;

    h1, h2, h3, h4, h5, h6{
        font-weight: inherit;
    }
    
    .text1, .text2, .text3 {
        p {
            img {
                width: 100%;
            }
        }
    }
}

.footer-c {
    padding: 50px 0;

    .links {
        text-align: center;
        display: flex;
        justify-content: center;
        align-items:center;

        a, a:hover {
            text-decoration: none;
            color: inherit;
            font-size: 16px;

            &:hover{
                font-weight: bold;
            }
        }
    }

    .logo{
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 15px;
        margin-bottom: 15px;

        img{
            width: 175px;
            height: 70px;
            object-fit: contain;
        }
    }

    .copyright{
        font-size: 12px;
        text-align: center;
    }
}

.banner-c{
    height: calc(100vh - 190px);
    padding: 0 8.3%;

    .image{
        background-size: cover;
        background-repeat: no-repeat;
        background-origin: content-box;
        width: 100%;
        height: 100%;
    }

    .overlay{
        background-color: rgba(0,0,0,0.40);
        background-origin: content-box;
        width: 100%;
        height: 100%;

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .banner-text {
        font-size: 20px;
        text-align: center;
        padding: 0 30px;

        h1{
            font-size: 80px;
            font-weight: inherit;
        }

        h2{
            font-size: 60px;
            font-weight: inherit;
        }

        h3{
            font-size: 50px;
            font-weight: inherit;
        }
    }
}

.gallery-c {
    padding: 50px calc(8.33% + 50px);

    .header{
        text-align: center;
        font-size: 20px;
        padding-bottom: 20px;
    }

    .load-more{
        font-size: 14px;
        padding-top: 25px;
        text-align: center;
        span{
            &:hover{
                font-weight: bold;
                cursor: pointer;
            }
        }
    }

    .image-column {
        img{
            width:100%;
            margin:15px 0;
        }
        max-height: 100vh;
        transition: max-height 2s linear;
        overflow: hidden;
    }
}

.text-area-c{
    padding: 50px 15px;

    .text-section{
        overflow: hidden;

        h1{ font-size: 60px; font-weight: inherit; }
        h2{ font-size: 45px; font-weight: inherit; }
        h3{ font-size: 30px; font-weight: inherit; }

        blockquote{
            text-indent: -10px;
            padding: 20px 11px;
            font-weight: inherit;

            & > *{
                display: inline;
            }
        }

        a{
            color:inherit;
            border-bottom: 1px solid currentColor;

            &:hover{
                color:inherit;
                text-decoration: none;
                border-bottom: none;
            }
        }

        p{ white-space: pre-wrap; }

        table{
            margin: 20px 0 calc(20px + 1rem);
            display: flex;
            flex-direction: column;

            th, td{
                width: 150px;
                word-wrap: break-word;
                padding: 10px 20px;
                text-align: center;
                border: 1px solid currentColor;
            }
        }

        ol, ul{
            display: flex;
            align-items: flex-start;
            flex-direction: column;

            li{ margin-right: 25px; }
        }

        pre{
            color: white;
            background-color: #101010;
            padding: 40px 30px;
            text-align: left;
            border:1px solid white;
            border-radius: 10px;
        }
    }
}

.navbar-c {
    width: 100%;
    position: fixed;
    z-index: 1000;
    width: 100%;
    top:0;
    padding: 15px 0;

    .top{
        height:80px;
        padding-bottom: 15px;
        display: flex;
        justify-content: center;
    }

    img {
        height: 100%;
        object-fit: contain;
        cursor: pointer;
    }

    .bottom {
        border-top: 1px solid currentColor;
        border-bottom: 1px solid currentColor;
        height: 60px;

        display: flex;
        justify-content: center;
        align-items: center;

        .text-menu {
            font-size: 14px;
            text-align: center;
            line-height: 12px;
        }

        a{
           text-decoration: none;
           color: inherit;
            &:hover{
                text-decoration: none;
                color: inherit;
                font-weight:bold;
            }
        }
    }
}
