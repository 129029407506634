@media only screen and (max-width: 1024px){
    .banner-a {
        height: 100vh;

        .banner-overlay {
            max-width: 100%;
            margin-left: 0;
            flex: none;
        }

        .banner-slogan {
            padding: 50px 0 0 ;
            font-size: 30px;
            line-height: 32px;
        }

        .banner-text {
            font-size: 12px;
        }

        .banner-button-container {
            flex-direction: column;
            align-items: center;
        }

        .banner-button-text {
            margin: 5px;
            font-size: 12px;
            display: inline-block;
        }
    }

    .card-area-a {
        position: relative;
        padding-bottom: 0 !important;

        .offset-3{
            margin: 0;
        }

        .col-2{
            width:60%;
            margin-left: 20%;
            margin-right: 20%;
        }

        .card-area-header {
            font-size: 14px;
        }

        .card-area-container {
            max-width: 100%;
            flex: none;

            p {
                font-size: 12px;
                padding-bottom: 50px;
                margin:0;
            }
        }
    }

    .navbar-a{
        .navbar-menu-button{
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            z-index: 1500;
        }

        .navbar-logo{
            flex: 0 0 55%;
            max-width: 55%;
        }

        .navbar-panel{
            height:100vh;
            z-index: 1200;
            width: 100vw;
            top:0;
            position: fixed;
            padding:70px 0;

            .navbar-menu{
                padding: 15px;
                font-size: 18px;
            }
        }
    }

    .text-area-a {
      .text-area {
        width: 100%;
        max-width: 100%;
        flex: none;
        margin: 0;
        padding: 50px 20px;
      }
    }

    .gridarea-a{
        .text-cell, .image-cell{
            flex: none;
            max-width: 100%;
        }
    }
}
