.footer-a{
    padding: 50px 0;

    .logo-row{
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;

        img{
            width: 175px;
            height: 100%;
            object-fit: contain;
            object-position: left;
        }
    }

    .link-row{
        display: flex;
        justify-content: center;
        align-items:center;
        padding: 5px 0;

        a, a:hover{
          text-decoration: none;
          color:inherit;
          font-size: 14px;
          margin-bottom: 1px;

          &:hover{
              border-bottom: 1px solid currentColor;
              margin-bottom: 0;
          }
      }
    }

    .social-row{
        display: flex;
        justify-content: center;
        margin-top:20px;

        img{
            width:30px;
            height: 30px;
            object-fit: contain;
            margin: 5px;
        }
    }

    .text-row{
        font-weight: 200;
        font-size: 12px;
        text-align: center;
        margin-top: 20px;
    }
}

.navbar-a {
  height: 70px;
  width: 100%;
  position: fixed;
  top:0;
  z-index: 1000;
  transition: background-color 0.25s linear;

    .navbar-logo {
        display: flex;
        justify-content: flex-start;
        align-items:center;
        height: 70px;

        img {
            height: 70px;
            width: 100%;
            object-fit: contain;
            object-position: left;
        }
    }

  .navbar-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 200;
    text-align: center;
    line-height: 12px;
  }

  a, a:hover {
     text-decoration: none;
     color: inherit;
      &:hover{
         font-weight:600;
      }
  }
}

.banner-a {
  height: 90vh;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;

  .banner-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .banner-slogan {
    font-size: 45px;
    letter-spacing: 6px;
    font-weight: 600;
    text-align: center;
  }

  .banner-text {
    font-size: 16px;
    font-weight: 400;
    text-align: center;
  }

  .banner-button-container{
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .banner-button-text {
    font-size: 14px;
    text-align: center;
    font-weight: 200;
    margin: 0 20px;

    padding: 8px 12px;
    border-radius: 8px;

    &:hover{
        font-weight: 300;
        background: rgba(255, 255, 255, 0.3);
    }
  }

  .banner-button {
    text-decoration: none;
    color: inherit;
  }
}

.card-area-a {
  padding: 50px 15px 50px;

  .card-area-header {
    font-size: 20px;
    font-weight: 200;
    text-align: center;
    padding: 0 0 50px;
  }

  .card-area-container {
    text-align: center;

    img {
      width: 100%;
      object-fit: cover;
      object-position: center;
    }

    a {
      text-decoration: none;
      color: inherit;

      &:hover{
        font-weight:bold;
      }

      & > img:hover {
        padding: 5px;
        transition: padding linear 0.08s;
      }
    }

    p {
        font-size: 14px;
        padding: 10px 0 0;
    }
  }
}

.text-area-a {
  .text-area {
    padding: 50px 0;
  }

  .text-area-text {
    font-size: 14px;
    font-weight: 200;
    line-height: 2;
    letter-spacing: 0.5px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h1, h2, h3{
      font-weight: inherit;
    }
  }

  .text-area-image {
    margin: 15px 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.gridarea-a{
  .text-cell{
    font-size:16px;
    text-align: center;
    font-weight: 200;
    padding: 4% 8%;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    h1, h2, h3 {
      font-weight: inherit;
    }

    a{
      text-decoration: none;
      color:inherit;

      display: block;
      border: 1px solid black;
      padding: 5px 10px;
      border-radius: 8px;

      &:hover{
        background-color:rgba(255, 255, 255, 0.3);
      }
    }
  }

  .image-cell{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.map-area-a{
  span{
    width:100%;
    height:80vh;
  }

  iframe{
    width: 100%;
    height:80vh;
  }
}
