@media only screen and (max-width: 1024px){
    .banner-b{
        .overlay{
            .slogan{
                h1{
                    font-size: 45px;
                    margin:0;
                }

                h2{
                    font-size: 35px;
                    margin:0;
                }

                h3{
                    font-size: 25px;
                    margin:0;
                }
            }
        }

        .arrow{
            font-size: 30px;
            margin-bottom: 30px;
        }
    }

    .grid-area-b {
        height: 100%;

        .col-4 {
            flex: none;
            max-width: none;
        }

        .grid {
            height: auto;

            .cover {
                min-height: 40vh;
                padding: 30px 15px;
            }
        }
    }

    .gallery-b {
        padding: 20px 0 30px;

        .offset-3 {
            margin-left: 0;
        }

        .col-6 {
            flex: none;
            max-width: none;
        }

        .image {
            display: flex;
            justify-content: center;
            align-items:center;
        }
    }

    .footer-b {
        height: 100%;
        padding: 30px 0;

        .col-1 {
            flex: none;
            max-width: none;
        }

        .text {
            padding: 0;
            border-right: 0;
        }
    }

    .card-area-b {
        padding: 35px 0;

        .header-overlay {
            .header {
                padding: 0;
            }

            .subHeader {
                padding: 0 0 15px;
            }
        }

        .col-3 {
            flex: none;
            max-width: 90%;
        }
    }

    .tabbed-area-b{
        .tabs{
            overflow: scroll;
            height: 70px;

            .tab{
                height: 70px;
                width:400px !important;
                display:flex;
                justify-content: center;
                align-items: center;
                padding: 20px;
                white-space:nowrap;
                border-right: 1px solid currentColor;

                &:last-child{
                    border:none;
                }
            }
        }

        .text{
            padding: 50px 20px;
        }
    }

    .navbar-b{
        width:100vw;

        .menu{
            display:flex;
            justify-content: center;
            align-items: center;
        }

        .menu-panel{
            display:block;
            width:100vw;
            position: fixed;
            top:70px;
            z-index: 1000;
            padding:10px 0;
            background-color: inherit;
            border-top: 1px solid currentColor;
        }

        .links{
            padding: 10px 0;
            color: inherit;
        }
    }
    .form-area-b{
        padding:0;

        .image{
            flex: none;
            max-width:100%;
            height:40vh;
            margin: 0;
        }

        .form{
            flex: none;
            max-width: 100%;
            padding:50px 60px;
        }
    }
}
