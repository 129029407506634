body{
    font-family: 'Quicksand', sans-serif;
}

.navbar-b{
    height: 70px;
    justify-content: center;
    top:0;
    position: fixed;
    z-index: 1000;
    width: 100%;
    transition: background-color 0.25s linear;

    .logo{
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        cursor: pointer;
    }

    .links{
        display: flex;
        justify-content: center;
        align-items: center;

        a, a:hover{
            color: inherit;
            text-decoration: none;
        }

        a:hover{
            font-weight: bold;
        }
    }
}

.footer-b {
    height: 70px;
    display: flex;
    justify-content: center;
    align-items:center;

    a, a:hover {
        text-decoration: none;
        color: inherit;
        font-size: 16px;

        &:hover{
            font-weight: bold;
        }
    }

    .text {
        text-align: center;

        padding-right: 15px;
        border-right: 1px solid currentColor;
    }
}

.gallery-b {
    padding: 50px 0;

    .header {
        font-size: 30px;
        font-weight: 600;
        text-align: center;
        padding-bottom: 30px;
    }

    .image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg  {
        height: 80px;
        width: 30px;
    }

    .image-gallery-svg {
        stroke-width: 0.8;
    }
}

.banner-b{
    height:100vh;
    overflow: hidden;

    img{
        width: 100%;
        height: 100vh;
        position: absolute;
        object-fit: cover;
    }

    video{
        object-fit: cover;
    }

    .overlay{
        position: absolute;
        height:100vh;
        display: flex;
        justify-content: center;
        align-items: center;

        .slogan{
            text-align: center;

            a, a:hover{
                text-decoration:none;
                color: inherit;
            }

            h1{
                font-size: 80px;
                font-weight: inherit;
            }

            h2{
                font-size: 70px;
                font-weight: inherit;
            }

            h3{
                font-size: 60px;
                font-weight: inherit;
            }
        }
    }
    .arrow {
        position: absolute;
        font-size: 40px;
        width:100%;
        text-align: center;
        bottom:0;
        margin-bottom: 50px;
    }
}

.card-area-b {
    padding: 50px 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .header-overlay {
        text-align: center;

        .header {
            font-size: 35px;
            font-weight: 600;
            letter-spacing: 3px;
            padding: 5px 0;
        }

        .subHeader {
            font-weight: 200;
            padding: 5px 0;
        }
    }

    .box {
        margin: 20px;
        padding: 0;
        height: 60vh;
        max-height: 500px;

        display: flex;
        justify-content: center;
        align-items: center;

        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

        .cover {
            height: 100%;
            width: 100%;
            padding: 0 15px;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            p {
                padding: 5px;
            }

            h1, h2, h3, h4, h4, h5, h6{
                font-weight: inherit;
            }

            a, a:hover {
                text-decoration: none;
                border-bottom: 1px solid currentColor;
                color: inherit;
                font-size: 16px;

                &:hover{
                    font-weight: bold;
                }
            }
        }
    }
}

.tabbed-area-b{
    .tabs{
        display: flex;
        justify-items: center;
        padding:0;

        .tab{
            text-align: center;
            height: 80px;
            display: flex;
            justify-content: center;
            align-items: center;

            font-size:18px;
            cursor: pointer;

            &:hover{
                font-weight: bold !important;
            }
        }
    }

    .text{
        min-height: 300px;
        padding: 50px 80px;
        display:flex;
        justify-content: center;
        align-items: center;
    }
}

.grid-area-b {
    height: 40vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .grid {
        height: 100%;
        padding: 0;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    .cover {
        height: 100%;
        padding: 0 15px;
        background-color: rgba(0, 0, 0, 0.4);

        display: flex;
        justify-content: center;
        flex-direction: column;

        a {
            text-decoration: none;
            color: inherit;

            border: 1px solid white;
            padding: 5px 10px;
            border-radius: 8px;

            &:hover {
            background-color:rgba(255, 255, 255, 0.3);
            }
        }

        h1, h2, h3, h4, h5, h6{
            font-weight: inherit;
        }
    }
}

.form-area-b{
    padding:50px 0;
    display: flex;
    justify-content: center;
    min-height: 80vh;

    .image{
        margin: -50px 0;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .form{
        padding:0 80px;
        display: flex;
        justify-content: center;
        flex-direction: column;

        .title{
            font-size:30px;
            padding-bottom: 10px;
        }

        input, textarea{
            background-color: transparent;
            border: 2px solid currentColor;
            height:40px;
            font-size: 16px;
            width: 100%;
            margin:10px 0;
            padding: 10px;
            font-weight: inherit;

            &::placeholder{
                color:inherit;
            }
        }

        textarea{
            height: 150px;
        }

        button{
            height:35px;
            font-size: 16px;
            padding: 0 40px;
            background-color: transparent;
            border: 2px solid currentColor;
            margin: 10px 0;
            font-weight: inherit;
        }

        .msg{
            margin: 10px 0;
            font-size: 16px;
            font-weight: bold;
            &.failed{ color: red }
            &.passed{ color: green }
        }

        input, textarea, button {
            color: inherit;
        }
    }
}
