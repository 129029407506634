.footer-d {
    height: 200px;
    justify-content: center;

    .social-media {
        padding: 50px 0;

        img {
            width: 25px;
            height: 25px;
            margin: 5px;
        }

        .copyright {
            font-size: 14px;
            padding: 15px 0;
        }
    }

    .block {
        width: 50px;
    }

    .menu-item {
        display: flex;
        justify-content: center;
        padding: 50px 0;

        .links {
            padding: 0 10px;
            text-align: center;

            a, a:hover{
                text-decoration: none;
                font-size: 16px;
                color: inherit;

                &:hover{
                    font-weight: bold;
                }
            }
        }
    }
}
