@media only screen and (max-width: 1024px){
    .banner-c {
        padding: 20px 30px;

        @media only screen and (max-width: 750px) {
            .banner-text {
                overflow: hidden;
                font-size: 16px;

                h1{
                    font-size: 36px;
                }

                h2{
                    font-size: 32px;
                }

                h3{
                    font-size: 28px;
                }
            }
        }
    }

    .gridarea-c {
        @media only screen and (max-width: 770px) {
            padding: 50px 0;

            .text-container {
                position: relative;
                z-index: 0;
                display: block;
                width: 100%;

                .col-4 {
                    max-width: 100%;
                    flex: 0 0;
                }

                .offset-2 {
                    margin-left: 0;
                }

                .text-box {
                    width: 100%;
                    height: 100%;

                    @media only screen and (max-width: 320px) {
                        h1 {
                            font-size: 50px;
                        }
                        h2 {
                            font-size: 30px;
                        }
                        h3 {
                            font-size: 20px;
                        }
                        p {
                            font-size: 12px;
                        }
                    }
                }
            }

            .image-container {
                width: 100%;
                display: block;

                img {
                    width: 100%;
                }
            }
        }
    }

    .footer-c {
        .links {
            .col-1 {
                max-width: 100%;
            }
        }

        @media only screen and (max-width: 750px) {
            .links {
                flex-direction: column;
                display: block;
                padding: 8px calc(15px + 8.333%);

                .col-1 {
                    max-width: 50%;
                    float: left;
                    padding: 8px 0;
                    display: block;
                }
            }

            .logo {
                img {
                    width: 150px;
                }
            }
        }
    }

    .maparea-c {
        height: 100%;
        padding: 50px 0;

        .text {
            padding: 30px 0;
        }

        .col-4 {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }

    .card-area-c {
        .col-4 {
            padding: 0 20px;
        }

        @media only screen and (max-width: 750px) {
            .col-4 {
                flex: 0 0 100%;
                max-width: 100%;

                padding: 20px 15px;
            }
        }
    }

    .navbar-c {
        padding: 15px 0 0;

        .top {
            height: 50px;
            padding: 0 0 10px;
        }

        .arrow {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .bottom {
            border-bottom: 0;
            height: 6px;

            .menu-panel {
                display: block;
                width: 100vw;
                position: fixed;
                top: 66px;
                z-index: 1000;
                font-size: 10px;

                .link {
                    padding: 10px 0;
                    text-align: center;
                    border-bottom: 1px solid currentColor;
                }
            }
        }
    }

    .gallery-c{
        padding: 50px 15px;
    }

    .text-area-c {
        .text-section {
            table {
                display: block;
                overflow: auto;
            }
        }

        .offset-2 {
            margin-left: 0;
        }

        .col-8 {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
}
