@media only screen and (max-width: 1024px){
    .footer-d{
        height: auto;
        flex-direction: column-reverse !important;

        .block{
            display:none;
        }

        .social-media{
            max-width:100%;
            flex: 0 0 100%;
            padding: 30px;

            .copyright{
                font-size: 12px;
            }
        }

        .menu-item{
            max-width:100%;
            flex: 0 0 100%;
            padding: 50px 30px 20px;
            justify-content: flex-start;
            display: block;

            .col-2{
                float:left;
                padding: 5px 20px 5px 0;
                max-width: 100%;
                display: block;
                width:auto;
                word-wrap: normal;

                a, a:hover{
                    font-size: 12px;

                    &:hover{
                        font-weight: inherit;
                        text-decoration: underline;
                    }
                }
            }
        }
    }

}